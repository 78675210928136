import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import infiniteScroll from 'vue-infinite-scroll';
import dayjs from 'dayjs';

import './main.scss';

router.beforeEach((to, from, next) => {
  let isLogin = window.sessionStorage.getItem('token'); // 是否登录
  if (isLogin) {
    if (to.path === '/login') {
      next({ path: '/' });
    }
  } else {
    if (to.path !== '/login' && to.path !== '/forget') {
      next({ name: 'Login' });
    }
  }
  next();
});
Vue.use(ElementUI);
Vue.use(infiniteScroll);

Vue.config.productionTip = false;

Vue.filter('formatDate', (timestamp) => {
  return dayjs(timestamp * 1000).format('YYYY年MM月DD日 HH点mm分');
});

Vue.directive('permission', {
  bind: function (el, binding) {
    let check = store.getters.check(binding.arg);
    if (!check) {
      // console.log('remove 无权限', binding.arg);
      el.style.display = 'none';
      el.remove();
    }
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    export_sp: null,
    e_id: 0,
    menus: [],
    role_codes: [],
    buttons: [],
  },
  mutations: {
    setRbac(state, data) {
      state.menus = data.menus;
      state.role_codes = data.role_codes;
      state.buttons = data.buttons;
    },
    setEID(state, data) {
      state.e_id = data.e_id || 0;
    },
    setExportSP(state, data) {
      state.export_sp = data.sp || '';
    },
  },
  actions: {
    SET_RBAC({ commit }, data) {
      // console.log('Init rbac');
      commit('setRbac', data);
    },
    SET_E_ID({ commit }, data) { 
      commit('setEID', data);
    },
    SET_EXPORT_SP({ commit }, data) {
      commit('setExportSP', data);
    },
  },
  getters: {
    check: (state) => (key) => {
      // console.log('Getters => check', key, state.buttons.includes(key));
      return state.buttons.includes(key);
    },
    get_e_id: (state) => () => state.e_id,
    get_export_sp: (state) => () => state.export_sp,
  },
  modules: {},
});

import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/index/index.vue';
Vue.use(VueRouter);
// 图标
// el-icon-office-building      企业管理
// el-icon-data-line            运营管理
// el-icon-document-copy        订单管理
// el-icon-user                 账号管理
// el-icon-more-outline         其他
// el-icon-price-tag            首页
// el-icon-takeaway-box         内容管理
// el-icon-setting              体系配置
const routes = [
  {
    path: '/',
    redirect: '/',
    name: 'Index',
    component: () => import('@/components/Container.vue'),
    children: [
      {
        path: '/',
        name: '首页',
        icon: 'el-icon-price-tag',
        component: () => import('../views/home/home.vue'),
      },
      {
        path: '/model',
        name: '内容管理',
        icon: 'el-icon-s-help',
        component: Index,
        children: [
          {
            path: '/model',
            name: '模型库',
            component: () => import('../views/model/index.vue'),
          },
          {
            path: '/caseDetails',
            name: '案例库详情',
            component: () => import('../views/case/components/caseLndex.vue'),
          },
          {
            path: '/caseAdd',
            name: '案例库新增',
            component: () => import('../views/case/components/EditCase.vue'),
          },
          {
            path: '/LinkBuilding',
            name: '关联构建',
            component: () => import('../views/case/components/Part.vue'),
          },
        ],
      },
      {
        icon: 'el-icon-takeaway-box',
        name: '案例相关',
        path: '/programme',
        component: Index,
        children: [
          {
            name: '案例管理',
            path: '/programme',
            component: () => import('../views/programme/index.vue'),
          },
          {
            name: '案例编辑',
            path: '/programmeEdit',
            meta: {
              isLock: 0,
            },
            component: () => import('../views/programme/edit.vue'),
          },
          {
            name: '案例查看',
            path: '/programmeView',
            meta: {
              isLock: 1,
            },
            component: () => import('../views/programme/edit.vue'),
          },
        ],
      },

      {
        icon: 'el-icon-takeaway-box',
        name: '系统管理',
        path: '/system',
        component: Index,
        children: [
          {
            name: '菜单编辑',
            path: '/menus',
            component: () => import('../views/system/menus.vue'),
          },
          {
            name: '角色编辑',
            path: '/roles',
            component: () => import('../views/system/roles.vue'),
          },
        ],
      },

      {
        icon: 'el-icon-takeaway-box',
        name: '云空间管理',
        path: '/cloud',
        component: Index,
        children: [
          {
            name: '构件管理',
            path: '/cloud',
            component: () => import('../views/cloud/cloud.vue'),
          },
          {
            name: '用户空间',
            path: '/userSpace',
            component: () => import('../views/cloud/userSpace.vue'),
          },
          {
            name: '内存管理',
            path: '/memory',
            component: () => import('../views/memory/memory.vue'),
          },
          {
            name: '渲染器管理',
            path: '/renderer',
            component: () => import('../views/renderer/renderer.vue'),
          },
          {
            path: '/case',
            name: '案例库',
            component: () => import('../views/case/case.vue'),
          },
        ],
      },
      {
        path: '/orderManagement',
        name: '订单管理',
        icon: 'el-icon-s-help',
        component: Index,
        children: [
          {
            path: '/orderManagement',
            name: '订单',
            component: () => import('../views/orderManagement/orderManagement.vue'),
          },
          // {
          //   path: "/invoice",
          //   name: "发票管理",
          //   component: () => import("../views/invoice/invoice.vue"),
          // },
        ],
      },
      {
        path: '/groupList',
        name: '账号管理',
        icon: 'el-icon-s-help',
        component: Index,
        children: [
          {
            path: '/groupList',
            name: '角色管理',
            component: () => import('../views/groupList/groupList.vue'),
          },
          {
            path: '/accountManagement',
            name: '我的资料',
            component: () => import('../views/accountManagement/accountManagement.vue'),
          },
          {
            path: '/useTime',
            name: '使用时长',
            component: () => import('../views/useTime/useTime.vue'),
          },
        ],
      },
      {
        path: '/PrivateEnterpriseSystem',
        name: '企业私有体系',
        icon: 'el-icon-setting',
        component: Index,
        children: [
          {
            path: '/systemSetting',
            name: '体系配置',
            component: () => import('../views/PrivateEnterpriseSystem/systemOneList.vue'),
          },
          // {
          //   path: "/tagSetting",
          //   name: "标签配置",
          //   component: () =>
          //     import("../views/PrivateEnterpriseSystem/labelStoreroom.vue"),
          // },
          {
            name: '模型列表',
            path: '/systemModelList',
            component: () => import('../views/systemModelList/systemModelList.vue'),
          },
        ],
      },
      {
        path: '/personalSetting',
        name: '系统设置',
        icon: 'el-icon-message',
        component: Index,
        children: [
          {
            path: '/personalSetting',
            name: '登录日志',
            component: () => import('@/views/system/personalSetting.vue'),
          },
          {
            path: '/system',
            name: '系统消息',
            component: () => import('@/views/system/index.vue'),
          },
        ],
      },
      {
        icon: 'el-icon-s-cooperation',
        name: '商机管理',
        path: '/businessManagement',
        component: Index,
        children: [
          {
            name: '线索中心',
            path: '/businessManagement',
            component: () => import('@/views/cluesManage/index.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/login/forget.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
